import React from "react"
import "react-id-swiper/lib/styles/css/swiper.css"
import { Fade } from "react-reveal"
import Swiper from "react-id-swiper"
import img1 from "@images/pexels-oleg-magni-2058128.jpg"
import img2 from "@images/agencia-innn-V16ESPxKrOU-unsplash.jpg"
import img3 from "@images/christina-wocintechchat-com-6Dv3pe-JnSg-unsplash.jpg"

const valueprops = [
  {
    heading: "We provide support in 21 seconds or less.",
    paragraph:
      "We have fully staffed support desk of 6 Full Time engineers dedicated to make sure that you can talk to a technical support resource in under 21 seconds. All service models with unlimited help desk come with this value, and in our upper pricing tiers we even guarantee it!",
    img: img1,
  },
  {
    heading: "We offer flexible services and pricing.",
    paragraph:
      "Our service offerings are designed with your business needs in mind. We understand that not every small business is the same, budgets, business hours, security, compliance, and specialty LOB application support all differ from business to business. That is why we have flexible and customized pricing tiers to support you where you are and anywhere you go as you grow!",
    img: img2,
  },
  {
    heading: "We have locally-staffed IT engineers.",
    paragraph:
      "Our support engineers go through an extensive vetting process to ensure competence and expertise. Many IT companies outsource their help desk over seas or to large corporations to cut down on costs. At IT Specialists, we invest in local IT experts that live in and around the greater cities of Colorado, including Colorado Springs, Denver, Fort Collins, Pueblo, and Boulder. These are the people who serve in your community and with whom you share the roads and grocery stores with; additionally allowing us to dispatch onsite techs quickly!",
    img: img3,
  },
]

class WhyChooseUsSwiper extends React.Component {
  render() {
    const params = {
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 1000,
      loop: false,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: "#value-next",
        prevEl: "#value-prev",
      },
    }
    return (
      <div className="value-props-swiper-wrapper">
        <div className="row align-items-center">
          <div className="col-lg-11">
            <Swiper {...params}>
              {valueprops.map((valueprop, i) => {
                return (
                  <div className="h-auto" key={i}>
                    <div className="row align-items-center h-100">
                      <div className="col-lg-7 order-2 order-lg-1">
                        <div className="bg-light value-prop h-100">
                          <div className="number-decor">{++i}</div>
                          <h6 className="text-uppercase text-secondary">
                            Why Work with Us?
                          </h6>
                          <h3>{valueprop.heading}</h3>
                          <p className="pt-4">{valueprop.paragraph}</p>
                        </div>
                      </div>
                      <div className="col-lg-5 order-1 order-lg-2 mb-5 mb-lg-0">
                        <div
                          className="img-backdrop lines-bg value-prop-img"
                          style={{
                            backgroundImage: `url(${valueprop?.img})`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </Swiper>
          </div>
          <div className="col-lg-1 d-none d-lg-block">
            <div className="value-props-swiper-nav">
              <Fade right>
                <div>
                  <span id="value-next" className="d-block swiper-btn-next">
                    <i className="fa fa-long-arrow-right"></i>
                  </span>
                </div>
              </Fade>
              <Fade right delay={100}>
                <div>
                  <span id="value-prev" className="d-block swiper-btn-prev">
                    <i className="fa fa-long-arrow-left"></i>
                  </span>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WhyChooseUsSwiper
