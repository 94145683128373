import React from "react"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"
import quote from "@images/quote-white.svg"

const slugify = require("slugify")

const reviews = [
  {
    quote:
      "I have had the pleasure of working IT Specialists for over the past eight years and I cannot say enough about the high quality service my organization receives.",
    name: "Wilfred Romero",
    positionCompany: "The Arc",
  },
  {
    quote:
      "Colorado Canine Orthopedics has been working with IT Specialists for well over a decade and the IT Specialists team has been integral to our success as the largest veterinary, orthopedic surgical practice in the country.",
    name: "Mike Bauer",
    positionCompany: "Colorado Canine Orthopedic",
  },
  {
    quote:
      "We have had many IT consultants and technicians in the past, and I can say with full-confidence, IT Specialists are the BEST.",
    name: "Asa Dyer",
    positionCompany: "OEL Worldwide Industries",
  },
  {
    quote:
      "Reliability and fast response time is what IT Specialists does better than any other IT company!",
    name: "Mary Casey",
    positionCompany: "Federal Document Shredding, RS.",
  },
]

class WhyChooseUsSwiper extends React.Component {
  render() {
    const params = {
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 1000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: false,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    }
    return (
      <div className="homepage-reviews-swiper-wrapper">
        <Swiper {...params}>
          {reviews.map((review, i) => (
            <div className="review-item" key={i}>
              <img alt="&quote;" className="quote" src={quote} width="35px" />
              <p className="h3 text-white font-italic font-weight-light pt-4">
                {review.quote}
                <br />
              </p>
              <Link
                className="small text-white-50 text-uppercase"
                to={`/our-clients/#${slugify(review.name.toLowerCase())}`}
              >
                Full Review
              </Link>
              <div className="text-white pt-4">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
              </div>
              <h4 className="mb-0 text-white">{review.name}</h4>
              <span className="text-white-50">{review.positionCompany}</span>
            </div>
          ))}
        </Swiper>
      </div>
    )
  }
}

export default WhyChooseUsSwiper
