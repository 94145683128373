import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import Form from "@components/form/form"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { Fade, Zoom, Slide } from "react-reveal"
import AnchorLink from "react-anchor-link-smooth-scroll"
import "react-lazy-load-image-component/src/effects/blur.css"
import { StaticImage } from "gatsby-plugin-image"

// swipers
import WhyChooseUsSwiper from "@components/swipers/why-us-swiper"
import ReviewsSwiper from "@components/swipers/homepage-reviews-swiper"

import pdfs from "../images/essential-qualities-to-look-for-in-an-IT-provider.pdf"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { show: false }
  }
  componentDidMount() {
    this.setState({ show: !this.state.show })
  }
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    // const posts = this.props.data.allSanityPost.edges
    // console.log(posts)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo title="Home" description={siteDescription} />
        <div className="hero-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <Fade left when={this.state.show}>
                  <h1 className="display-2">End to End Solutions in IT</h1>
                </Fade>
                <Fade right when={this.state.show}>
                  <h3>
                    Providing the highest quality managed services available in
                    the market
                  </h3>
                </Fade>
              </div>
            </div>
          </div>
          <Fade delay={250}>
            <div className="hero-cta">
              <div className="hero-anchor">
                <AnchorLink href="#services">
                  <p className="lead">
                    Learn how you can save money by investing in your IT
                    <br />
                  </p>
                  <span></span>
                </AnchorLink>
              </div>
            </div>
          </Fade>
        </div>
        <div className="below-atf">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-lg-9 text-center text-lg-left">
                <div>
                  <h3>Is your IT provider the RIGHT fit for your business?</h3>
                  <p>
                    Get the complete checklist that allows you to compare your
                    current IT provider against the best in the industry.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 text-center">
                <div className="py-3">
                  <a
                    className="btn btn-primary btn-lg btn-white gtg-btn"
                    // href="https://info.itspecialists.net/qualities-to-look-for-in-an-it-provider"
                    href={pdfs}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get the Guide
                    <i className="fa fa-arrow-circle-o-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="services" className="pre-services-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="pb-5 text-center">
                  <h6 className="text-uppercase text-secondary">
                    How We Can Help You
                  </h6>
                  <h2 className="display-4">
                    Work with a technology company that does business like you
                    do.
                  </h2>
                  <p className="py-4">
                    Our Managed Services offering goes beyond just the tools to
                    maintain and support your IT needs, additionally it provides
                    standards that we weigh against your organization and then
                    assist with aligning your business to these necessary items
                    for sustained business growth. Technology is no longer a
                    budget expense, but rather a strategic investment.
                  </p>
                  <Link
                    className="btn btn-primary m-2"
                    role="button"
                    to="/solutions/"
                  >
                    Find Out More
                  </Link>
                  <Link
                    className="btn m-2 btn-transparent"
                    role="button"
                    to="/contact/"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LazyLoadComponent>
          <div className="services-section bg-light">
            <div className="container">
              <div className="row">
                <Zoom>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link className="service-box" to="/managed-services/">
                      <div>
                        <StaticImage
                          alt="manageIT"
                          src="../images/managed-it.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">
                          Managed IT Services
                        </h4>
                        <p className="small">
                          World class support for all Managed IT services,
                          bundled into a flat fee.
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <Zoom delay={50}>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link className="service-box" to="/cloud-it-solutions/">
                      <div>
                        <StaticImage
                          alt="cloud"
                          src="../images/cloud-computing.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">
                          Cloud IT Solutions
                        </h4>
                        <p className="small">
                          Ensure that your data is safe and secure and managed
                          by the systems and networks on that external system.{" "}
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <Zoom delay={100}>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link className="service-box" to="/help-desk/">
                      <div>
                        <StaticImage
                          alt="HelpDesk"
                          src="../images/helpdesk.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">Help Desk</h4>
                        <p className="small">
                          Reliable and knowledgeable IT support a phone call
                          away.
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <Zoom delay={150}>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link className="service-box" to="/voip-and-connectivity/">
                      <div>
                        <StaticImage
                          alt="VoIP"
                          src="../images/voip.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">Hosted VoIP</h4>
                        <p className="small">
                          Use VoIP technology to maximize operations, costs and
                          maintenance.
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <Zoom delay={200}>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link className="service-box" to="/network-cabling/">
                      <div>
                        <StaticImage
                          alt="NetworkCabling"
                          src="../images/network-cabling.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">Network Cabling</h4>
                        <p className="small">
                          A full line of quality and performance cabling
                          services to accommodate all of your communication and
                          network needs.{" "}
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <Zoom delay={250}>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link className="service-box" to="/file-sync-and-share/">
                      <div>
                        <StaticImage
                          alt="FileSyncShare"
                          src="../images/file.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">
                          File Sync &amp; Share
                        </h4>
                        <p className="small">
                          Access, edit, and share files from your smartphone,
                          tablet, or any computer.
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <Zoom delay={300}>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link
                      className="service-box"
                      to="/backup-and-disaster-recovery/"
                    >
                      <div>
                        <StaticImage
                          alt="data-protection"
                          src="../images/data-protection.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">
                          Backup &amp; Disaster Recovery
                        </h4>
                        <p className="small">
                          We are experts in data protection with limited to zero
                          downtime.
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <Zoom delay={350}>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link className="service-box" to="/web-solutions/">
                      <div>
                        <StaticImage
                          alt="web-solutions"
                          src="../images/web-solutions.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">Web Solutions</h4>
                        <p className="small">
                          We handle all of your hosting, email and networking
                          functions so that you never had to log into a server.
                          <br />
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <Zoom delay={400}>
                  <div className="col-md-6 col-lg-3 mb-4">
                    <Link className="service-box" to="/special-projects/">
                      <div>
                        <StaticImage
                          alt="SpecialProjects"
                          src="../images/special-projects.png"
                          width={64}
                          layout="constrained"
                        />
                        <h4 className="my-3 text-primary">Special Projects</h4>
                        <p className="small">
                          Regardless of the project, as your business grows and
                          your needs change, we are the consistent resource you
                          can rely on to take on the task.
                          <br />
                        </p>
                      </div>
                    </Link>
                  </div>
                </Zoom>
                <div className="col-md-6 col-lg-9 text-center text-lg-left align-self-center mb-4">
                  <div className="pt-5 pt-md-0">
                    <a className="call-cta" href="tel:719-359-5995">
                      <Zoom delay={900}>
                        <div className="call-us-icon d-inline-block mb-3 mb-md-0">
                          <i className="fa fa-phone"></i>
                        </div>
                      </Zoom>
                      <div className="d-inline-block">
                        <Fade delay={750}>
                          <h2 className="display-4 m-0">719-359-5995</h2>
                          <p className="lead">
                            Call us today to discuss your IT needs with one of
                            our specialists.
                          </p>
                        </Fade>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-light about-section">
            <div className="container">
              <div className="row justify-content-around align-items-center">
                <div className="col-lg-6 mb-5 mb-lg-0">
                  <div className="img-backdrop dots-bg about-img">
                    <StaticImage
                      src="../images/pexels-olia-danilevich-4974913.jpg"
                      alt="about us"
                      className="img-fluid"
                      layout="constrained"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="bg-white about-txt">
                    <h6 className="text-uppercase text-secondary">
                      Who We Are
                    </h6>
                    <h3>About Us</h3>
                    <p className="py-4">
                      IT Specialists started in 1995 as a software development
                      company focused on business application software and
                      extended data collection interfaces. Our core focus was to
                      develop software that expediently collected data from
                      manufacturing systems, people and processes resulting in
                      near time productivity and payroll reports for management
                      and payroll departments.
                    </p>
                    <Link
                      className="btn btn-text-only"
                      role="button"
                      to="/about-us/"
                      title="About Us"
                    >
                      Learn More<i className="fa fa-arrow-circle-o-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="value-props-section">
            <div className="container">
              <LazyLoadComponent>
                <WhyChooseUsSwiper />
              </LazyLoadComponent>
              <Zoom>
                <div className="text-center pt-5">
                  <Link
                    className="btn btn-primary btn-lg"
                    role="button"
                    to="/8-reasons-to-choose-it-specialists/"
                  >
                    8 Reasons to Choose IT Specialists
                  </Link>
                </div>
              </Zoom>
            </div>
          </div>
        </LazyLoadComponent>

        <div className="reviews-contact-section">
          <div className="container">
            <div className="row justify-content-around">
              <Slide left>
                <div className="col-lg-5 mb-5 mb-lg-0">
                  <div className="text-center pt-5">
                    <h6 className="text-white-50 text-uppercase">Reviews</h6>
                    <h3 className="text-white">What Our Clients Have to Say</h3>
                    <div>
                      <LazyLoadComponent>
                        <ReviewsSwiper />
                      </LazyLoadComponent>
                    </div>
                  </div>
                  <div className="text-center">
                    <Link
                      className="btn btn-text-only"
                      role="button"
                      to="/our-clients/"
                    >
                      Our Clients<i className="fa fa-arrow-circle-o-right"></i>
                    </Link>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="col-lg-6">
                  <div className="contact-form-wrapper shadow-lg">
                    <h6 className="text-secondary text-uppercase text-center">
                      Contact Us
                    </h6>
                    <h3 className="text-center pb-4">
                      Send us a message and we&#39;ll get back to you soon!
                    </h3>
                    <LazyLoadComponent>
                      <Form
                        organization="oyTg4C9bZ5"
                        instance="4dr2FkRYMF"
                        successUrl="/thank-you"
                      >
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            name="Name"
                          />
                          <label htmlFor="name">Name</label>
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="email"
                            id="email"
                            name="Email address"
                            required
                          />
                          <label htmlFor="email">Email address</label>
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="email"
                            name="Message"
                            required
                          ></textarea>
                          <label htmlFor="message">Your message</label>
                        </div>
                        <div className="form-group">
                          <div className="recaptcha" />
                        </div>
                        <div className="form-group text-center">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Send Message
                          </button>
                        </div>
                      </Form>
                    </LazyLoadComponent>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
